/* base styles */
html, body, #root {
  height: 100%;
}

body {
  font-family: Raleway, sans-serif;
  margin: 0;
  font-size: 1em;
}

h1,h2,h3,p {
  margin: 0;
}